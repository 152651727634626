#control_panel {
  .header-part {
    height: 3.5em;
    display: flex;
    align-items: center;
    padding: 0.5em 0.2em;

    h2 {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .main-container {
    height: 78vh;
    width: 100%;
    padding: 1em;

    .control-panel-tabs {
      display: flex;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      flex-direction: row;
      column-gap: 1em;
      width: 100%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      list-style: none;
      margin: 0;
      padding: 0;
      margin-bottom: 2em;

      .tab-item {
        padding: 0.75em 0.75em;
        font-size: 16px;
        font-weight: 300;
        cursor: pointer;

        &.active-tab {
          color: $primary-color;
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }

  .content-container {
    padding: 0 1em;

    .tab-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2em;

      .btn-container {
        position: relative;

        .main-btn {
          padding: 0.5em 1.5em;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
    }

    .departments-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1em;
      row-gap: 1em;

      .department-card {
        border-radius: 8px;
        min-height: 190px;
        max-height: 190px;
        background-color: $white-color;
        padding: 1em;
        position: relative;
        cursor: pointer;

        .card-header {
          h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 0.3em;
            text-transform: uppercase;
          }

          .card-stats {
            display: flex;
            flex-direction: row;
            column-gap: 3em;
            margin-bottom: 0.75em;

            span {
              font-size: 12px;
              color: #999;
              text-transform: uppercase;
            }

            h4 {
              margin: 0;
              font-size: 16px;
              font-weight: 600;
            }
          }

          .card-details {
            margin-bottom: 1em;

            table {
              width: 100%;
              font-size: 14px;

              td {
                width: 40%;
              }

              th {
                font-weight: 500;
              }
            }
          }

          .action-btns {
            display: flex;
            flex-direction: row;
            column-gap: 1em;
            justify-content: flex-end;
            align-items: center;

            .check-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 0.3em;

              label {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }

          .info-btn {
            position: absolute;
            top: 0.75em;
            right: 0.75em;
            border: 0;
            padding: 0;
            background-color: transparent;
            color: lighten($primary-color, 30%);
          }
        }
      }
    }

    .add-categ-form {
      position: absolute;
      background-color: $white-color;
      top: 3em;
      right: 0;
      padding: 1em;
      width: 400px;
      z-index: 2;
      border-radius: 8px;

      h5 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 1em;
      }

      .form-group {
        margin-bottom: 1.5em;

        label {
          display: block;
          margin-bottom: 0.3em;
          font-size: 14px;
        }

        .department-name-input {
          width: 100%;
          padding: 0.5em 1em;
          border-radius: 8px;
          font-size: 16px;
        }
      }

      button {
        display: inline-block !important;
        font-size: 14px;
        margin: 0;
        padding: 0.5em 1.5em !important;
        min-width: initial !important;
        margin-right: 0.75em;

        &.add-more {
          color: $primary-color;
          border: 1px solid $primary-color;
        }

        &.cancel-btn {
          margin-right: 0;
        }
      }
    }

    .sub-cate {
      ul {
        background-color: $white-color;
        border-radius: 8px;
        padding: 1em;

        .header-part {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h4 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 1em;
          }

          .head-btn-container {
            position: relative;

            .main-btn {
              padding: 0.5em 1.5em;
              text-transform: capitalize !important;
              font-size: 14px;
            }
          }
        }

        li {
          padding: 0.5em 1em;
          border-bottom: 1px solid $light-grey;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;

          &:last-child {
            border-bottom: 0;
          }

          input {
            width: 70px !important;
            padding: 0.3em 0.5em;
            border-radius: 8px;

            &.categ-input {
              width: 400px !important;
            }
          }

          .sub-action-btns {
            button {
              padding: 0;
              background-color: transparent;
              border: 0;
              font-size: 16px;
              margin-right: 0.75em;

              &:last-child {
                margin-right: 0;
              }

              &.edit-btn {
                color: $black-color;
              }

              &.main-btn {
                text-transform: capitalize;
                display: inline-block;
                margin: 0;
              }

              &.no-border-btn {
                margin: 0;
              }

              &.delete-btn {
                color: $red-color;
              }
            }
          }
        }
      }
    }

    .user-details {
      padding: 1em;
      border-radius: 8px;
      border: 1px solid #f2f2f2;

      h2 {
        font-size: 20px;
        font-weight: 600;
      }

      table {
        width: 100%;
        font-size: 16px;
        color: $black-color;
        margin-bottom: 1.5em;

        td {
          width: 200px;
          padding: 0.25em;
        }

        th {
          font-weight: 500;
        }
      }

      .checkbox-container {
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0.5em;
          input {
            height: 20px;
            width: 20px;
            border-radius: 8px;

            &:checked {
              color: $primary-color;
            }
          }
          label {
            font-size: 16px;
            margin-left: 10px;
            font-weight: 500;
          }
        }
      }

      .action-btns-container {
        margin-top: 1.5em;

        button {
          display: inline-block;
          padding: 0.3em 1.5em !important;
        }
      }
    }
  }
}

.departments-list-main-container {
}

.departments-list-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Adjust height as needed */
}

.departments-list {
  overflow-y: auto; /* Enable vertical scrolling */
  flex-grow: 1; /* Allow this section to take available space */
  margin-bottom: 1px; /* Space for pagination */
  padding-bottom: 5%;
}

.departments-list-container > .MuiPagination-root {
  position: sticky; /* Keeps the pagination at the bottom */
  bottom: 0; /* Stick to the bottom of the container */
  padding-top: 5%;
  background-color: white; /* Ensure it stands out */
  z-index: 1; /* Keep it above other elements */
}
