#faqs {
    .header-part {
        height: 3.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em 0.2em;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 500;
        }

        button {
            text-transform: initial !important;
            padding: 0.5em 1.5em;
        }
    }

    .faqs-container {
        margin-top: 2em;

        .faq-item {
            border: 1px solid #f3f3f3;
            margin-bottom: 1em;
            padding: 1em;
            border-radius: 8px;
            position: relative; 

            .faq-question {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                margin-bottom: 0.3em;
            }

            .action-btns {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
                display: flex;
                flex-direction: row;
                column-gap: 0.3em;

                 button {
                    background-color: transparent;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    font-size: 14px;

                    &.edit-btn {
                        color: $primary-color;
                        border-color: $primary-color;
                    }

                    &.delete-btn {
                        color: $red;
                        border-color: $red;
                    }
                    
                 }
            }
        }
    }

    .form-container {
        padding: 1em;

        .form-group {
            margin-bottom: 1em;

            .ql-editor {
                min-height: 200px;
                max-height: 500px;
                margin-bottom: 1em;
            }
        }
    }

}