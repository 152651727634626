#survey_questions {
    position: relative;

    .header-part {
        height: 3.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em 0.2em;
        margin-bottom: 2em;
        position: relative;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 500;
        }

        button {
            text-transform: initial !important;
            padding: 0.5em 1.5em;
            display: inline-block;
        }

        .new-question {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $white-color;
            padding: 1em;
            width: 600px;
            z-index: 2;
            text-align: left;
            border-radius: 8px;

            h4 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 1em;
            }

            label {
                display: block;
                margin-bottom: 0.3em;
                font-size: 14px;
            }

            .question-field {
                display: block;
                width: 100%;
                padding: 0.85em;
                border-radius: 5px;
                border: 1px solid #e2e2e2;
                margin-bottom: 2em;
                font-weight: 600;
            }

            .form-check {
                label {
                    font-weight: 600;
                }
            }
            
            .btn-container {
                margin-top: 1em;
                display: flex;
                flex-direction: row;
                column-gap: 1em;
                justify-content: flex-end;
            }
        }
    }

    .quest-container {
       padding-bottom: 3em;

        .question-item {
            border-radius: 8px;
            background-color: $white-color;
            padding: 1em;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            align-items: center;
            border: 1px solid #f2f2f2;;

            .text-side {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 10px;
                
                h4 {
                    font-size: 18px;
                    margin: 0;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                span {
                    font-size: 12px;
                    background-color: $primary-color;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: inline-block;
                    text-transform: capitalize;
                    color: $white-color;
                }
            }

            .order-buttons {
                width: 77px;

                button {
                    border: 0;
                    margin-right: 8px;
                    background-color: $black-color;
                    color: $white-color;

                    &:disabled {
                        background-color: rgba(0,0,0,0.1);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.surveys-page {
    .card-style {
        border-radius: 8px;
        background-color: $white-color;
        padding: 1em;
        position: relative;
        cursor: pointer;
        margin-bottom: 1em;

        .card-harder {
            h1  {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                margin-bottom: 0.3em;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                span {
                    font-size: 12px;
                    text-transform: initial;
                    margin-left: 10px;
                    font-weight: 400;
                    background-color: $primary-color;
                    padding: 5px;
                    border-radius: 15px;
                    color: $white-color;
                }
            }

            p {
                color: $text-color;
                font-size: 14px;
            }
        }
    }
}