#client_page {
    .page-title {
        font-size: 25px;
        margin-bottom: 1em;
    }

    .stats-container {
        display: grid;
        column-gap: 1em;
        align-content: center;
        margin-bottom: 1.5em;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;

        .stats-card {
            width: 100%;
            background-color: $white-color;
            padding: 1em;
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            

            h4 {
                font-size: 22px;
                margin: 0;
            }
        }

        button {
            display: block;
        }
    }
}