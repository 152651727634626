.forbidden-container {
  text-align: center;
  margin-top: 100px;
}

.forbidden-title {
  font-size: 36px;
  color: #ff4d4d;
}

.forbidden-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.back-to-dashboard-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.back-to-dashboard-link:hover {
  text-decoration: underline;
}
