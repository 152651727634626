#dashboard {
    padding: 1em;
    %flex-column-display {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .header-part {
        @extend %flex-column-display;
        justify-content: space-between;
        margin-bottom: 1em;

        h2 {
            font-size: 22px;
            font-weight: 700;
        }

        .main-btn {
            padding: 0.5em 1.5em;
            text-transform: capitalize;
            font-size: 14px;
        }
    }

    .charts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1em;
        row-gap: 1em;

        .chart-card {
            
            background-color: $white-color;
            border: 1px solid $light-grey;
            border-radius: 8px;
            
            .card-header {
                border-bottom: 1px solid $light-grey;
                padding: 1em;

                h4 {
                    margin: 0;
                    font-size: 16px;
                }
            }

            .chart-container {
                padding: 1em;
            }
        }
    }
}