#user_account {
    .header-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;

        h2 {
            font-size: 22px;
            font-weight: 700;
        }

        .main-btn {
            padding: 0.5em 1.5em;
            text-transform: capitalize;
            font-size: 14px;
        }
    }

    .name-and-profile-pic {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2em;
        padding: 2em 2em;

        .profile-pic {
            width: 150px;
            height: 150px;
            border-radius: 75px;
            object-fit: cover;
        }

        .name-side {
            h2 {
                margin: 0;
                font-size: 30px;
                font-weight: 700;
            }

            span {
                font-size: 16px;
                display: block;
            }
        }
    }

    .profile-details {
        h4 {
            font-size: 16px;
            font-weight: 600;
        }

        .card-content {
            div {
                font-size: 16px;
                color: $black-color;
                font-weight: 300;
                margin-bottom: 2px;

                span {
                    font-weight: 500;
                }
            }
        }
    }
}