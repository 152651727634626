#chats {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: calc(100vh - 9em);
    column-gap: 1em;

    .inner-container {
        height: calc(100vh - 13.5em) !important;
    }

    .chat-history {
        .header-part {
            height: 3.5em;
            display: flex;
            align-items: center;
            padding: 0.5em 0.2em;

            h2 {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }
        }

        .action-btn {
            // padding: 0 0.3em;s
            height: 3em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1em;

            button {
                background-color: transparent;
                width: 100%;
                border-radius: 30px;
                border: 0;
                transition: $transition;
                color: $text-color;

                &.active-opt {
                    background-color: $primary-color;
                    color: $white-color;
                    transition: $transition;

                    &:hover {
                        background-color: $primary-color;
                    }
                }

                &:hover {
                    background-color: rgba(0,0,0,0.05);
                }
            }
        }

        .history-container {
            height: calc(100vh - 15.5em);
            padding: 0.5em 0;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .history-item {
                padding: 0.5em 0.75em;
                display: grid;
                grid-template-columns: 1fr 5fr auto;
                justify-content: flex-start;
                align-items: center;
                border-radius: 8px;
                transition: $transition;
                margin-bottom: 0.3em;
                cursor: pointer;

                .profile-img {
                    border-radius: 25px;
                    background-color: rgba(0,0,0,0.6);
                    height: 45px;
                    width: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    color: $white-color;
                }
                .message-and-name {
                    .name {
                        font-size: 14px;
                        font-weight: 600;
                        color: $black-color;
                        margin: 0;
                    }

                    .last-message {
                        font-size: 14px;
                        margin: 0;
                        color:rgba(0,0,0, 0.6);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 220px;
                    }
                }
                
                .time-and-badge {
                    align-self: flex-start;

                    .time {
                        font-size: 14px;
                        color: rgba(0,0,0,0.5);
                    }
                }

                &:hover {
                    background-color: rgba(0,0,0,0.09);
                    transition: $transition;
                }

                &.active-chat {
                    background-color: lighten($primary-color, 20%);

                    .name {
                        color: $white-color;
                    }

                    .last-message {
                        color: rgba(255, 255, 255, 0.6);
                    }

                    .time {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }

    .message-view {
        background-color: $light-grey;
        border-radius: 8px;
        box-shadow: inset 0 0 10px rgba(0,0,0,0.19);
        // padding: 0 1.5em;
        position: relative;
        // padding-top: 6em;

        .convert-to-case-info, .block-user {
            position: absolute;
            top: 70px;
            right: 1em;
            padding: 1em;
            background-color: $white-color;
            border-radius: 8px;
            width: 350px;

            h4 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0.75em;
            }

            label {
                display: block;
                font-size: 14px;
                margin-bottom: 0.3em;
            }

            input {
                display: block;
                width: 100%;
                padding: 0.85em;
                border-radius: 5px;
                border: 1px solid #e2e2e2;
                margin-bottom: 1.5em;
            }

            .action-btn {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 1em;

                button {
                    padding: 0.5em 2em;
                    text-transform: capitalize;
                    font-size: 14px;
                }
            }
        }

        .inner-container {
            overflow-y: auto;
            display: flex;
            flex-direction: column-reverse;
            background-color: transparent;
            height: calc(100vh - 15em);
            padding: 0 1.5em;
            border: 0;
            border-radius: 0;

            &::-webkit-scrollbar {
                display: none;
            }
            
        }

        .header-part {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 8px 8px 0 0;
            background-color: rgba(255, 255, 255, 0.5);
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
            // box-shadow: $box-shadow;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1em;
            align-items: center;

            h4 {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
            }

            .action-btn {
                display: flex;
                flex-direction: row;
                column-gap: 1em;
                align-items: center;

                button {
                    background-color: transparent;
                    
                    &.attend-chat {
                        padding: 0.5em 0.75em;
                        border-radius: 10px;
                        border: 1px solid $primary-color;
                        color: $primary-color;
                        background-color: $white-color;
                        transition: $transition;

                        &:hover {
                            background-color: $primary-color;
                            color: $white-color;
                        }
                    }

                    &.more-options {
                        border: 0;
                        color: $black-color;
                    }
                }
            }
        }

        .messages {
            padding-top: 5em;
            .message-container {
                display: grid;
                margin-bottom: 0.5em;

                .message-wrapper {
                    .image-files {
                        margin-bottom: 0.5em;

                        .file-download {
                            background-color: $white-color;
                            padding: 0.3em 0.5em;
                            border-radius: 5px;
                            font-size: 12px;
                            font-weight: 300;
                            display: flex;
                            position: relative;
                        }
                        
                        .img-attachment {
                            width: 300px;
                            max-width: 100%;
                            height: 200px;
                            object-fit: cover;
                            margin-bottom: 0.3em;
                            display: block;
                            border-radius: 15px;
                            cursor: pointer;
                        }
                    }
                    .message {
                        padding: 0.5em 1em;
                        display: inline-block;
                        
                    }

                    &.received {
                        .message {
                            max-width: 57%;
                            background-color: $white-color;
                            border: 1px solid rgba(0,0,0,0.09);
                            border-radius: 20px;
                        }
                        .time {
                            display: block;
                            margin-left: 1em;
                            font-size: 12px;
                            color: $text-color;
                            margin-top: 0.3em;
                        }
                    }

                    &.outgoing {
                        display: grid;
                        
                        .message {
                            max-width: 57%;
                            margin-left: auto;
                            background-color: $primary-color;
                            border-radius: 20px;
                            color: white;
                            text-align: left;
                            float: right;
                        }
                        .time {
                            text-align: right;
                            display: block;
                            margin-right: 1em;
                            font-size: 12px;
                            color: $text-color;
                            margin-top: 0.3em;
                        }
                    }

                }
            }
        }
    }

    .write-message-container {
        padding: 0 0.5em;
        width: 100%;
        position: absolute;
        bottom: 0.5em;
        left: 0;

        .file-preview {
            margin-bottom: 0.5em;
            padding: 0.3em;
            border-radius: 8px;
            background-color: rgba(255,255,255, 0.6);
            backdrop-filter: blur(5px);
            position: relative;

            .imgs-preview-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: 1em;
                row-gap: 0.5em;

                .img-container {
                    position: relative;
                    
                    .preview-image {
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    button {
                        position: absolute;
                        right: 0.2em;
                        top: 0.2em;
                        z-index: 9999;
                        color: $red-color;
                        border: 0;
                        background-color: #e6e6e6;
                        border-radius: 5px;
                        display: none;
                        transition: $transition;
                        font-size: 16px;
                        font-weight: 500;
                        
                    }

                    &:hover {
                        .remove-btn {
                            display: block;
                            transition: $transition;
                        }
                    }
                }
            }

            .h-spacing {
                content: '';
                height: 1px;
                margin: 1em 0;
                background-color: rgba(0,0,0,0.05);
                display: block;
            }

            .non-images-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: 1em;
                row-gap: 0.5em;

                .file-item {
                    background-color: $white-color;
                    padding: 0.3em 0.5em;
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 300;
                    display: flex;
                    position: relative;
          
                    .file-name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 130px;
                        display: inline-block;
                        color: $primary-color;
                    }
          
                    .file-size {
                        display: inline-block;
                        color: $text-color;
                    }
          
                    .remove-btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0 0.65em;
                        border: 0;
                        background-color: #e6e6e6;
                        border-radius: 0 5px 5px 0;
                        display: none;
                        transition: $transition;
                        font-size: 16px;
                        font-weight: 500;
                        height: 100%;
                        color: $red-color;
                    }
          
                    &:hover {
                        .remove-btn {
                            display: block;
                            transition: $transition;
                        }
                    }
                }
            }

            .not-valid-file {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
                padding: 0.3em;
                border-radius: 5px;
                color: $white-color;
                font-size: 12px;
                max-width: 250px;
                transition: $transition;

                p {
                    margin: 0;
                    margin-top: 0.3em;
                    display: none;
                    transition: $transition;
                }

                &:hover {
                    transition: $transition;
                    p {
                        display: block;
                        transition: $transition;
                    }
                }
            }
            
        }
        
        .write-message {
            display: grid;
            grid-template-columns: 4fr auto; 
            column-gap: 1em;
            align-items: center;
            box-shadow: $box-shadow;
            background-color: $white;
            border-radius: 8px;
            padding-right: 1em ;

            textarea {
                padding: 0.5em;
                margin: 0;
                min-height: 40px;
                max-height: 80px;
                resize: none;
                border: 0;
                background-color: transparent;
                outline: 0;
            }

            .action-btns {
                display: flex;
                flex-direction: row;
                column-gap: 0.75em;
                align-items: center;
                

                button, label {
                    border: 0;
                    padding: 0;
                    font-size: 10px;
                    background-color: transparent;
                    color: $text-color;
                    cursor: pointer;

                    &.send-btn {
                        background-color: $primary-color;
                        color: $white-color;
                        border-radius: 8px;
                        padding: 0.5em;
                    }
                }

                .v-divider {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 12px;
                    background-color: $text-color;
                }
            }
        }
    }

    .image-popup {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto !important;
        z-index: 9999;
        background-color: rgba(0,0,0,0.3);
        // backdrop-filter: blur(5px);
        border-radius: 8px;

        .popup-content {
            width: 100%;
            height: 86vh;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            

            .close-button {
                position: absolute;
                top: 1em;
                right: 1em;
                background-color: $white-color;
                border: 0;
                z-index: 9999;
            }

            img {
                width: 85%;
            }
        }
    }
}