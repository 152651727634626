#insights {
    .header-part {
        height: 3.5em;
        display: flex;
        align-items: center;
        padding: 0.5em 0.2em;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 700;
        }
    }

    .main-container {
        padding: 1em;
        
        .tabs-container {
            display: flex;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            flex-direction: row;
            column-gap: 1em;
            width: 100%;
            border-bottom: 2px solid rgba(0,0,0,0.06);
            list-style: none;
            margin: 0;
            padding: 0;
            margin-bottom: 2em;
            
    
            .tab-item {
                padding: 0.75em 0.75em;
                font-size: 16px;
                font-weight: 300;
                cursor: pointer;
    
                &.active-tab {
                    color: $primary-color;
                    border-bottom: 2px solid $primary-color;
                }
            }
        }
    }

    .feedback-view {
        h4 {
            font-size: 18px;
            margin-bottom: 1em;
        }

        .feedback-container {
            display: grid;
            grid-template-columns: 400px 2fr;
            column-gap: 1em;
            height: 65vh;

            .list-side {
                overflow-y: auto;

                .feedback-card {
                    background-color: $white-color;
                    padding: 1em;
                    border-top: 1px solid #f2f2f2;
                    // border-radius: 8px;
                    transition: $transition;
                    cursor: pointer;
                    position: relative;
                    

                    &.selected {
                        border-right: 4px solid $primary-color;
                        transition: $transition;
                    }

                    &.is-not-read {
                        border-left: 3px solid $red-color; 
                    }
    
                    .not-read {
                        content: '';
                        display: block;
                        background-color: $red-color;
                        width: 10px;
                        height: 10px;
                        transition: $transition;
                        position: absolute;
                        right: 1em;
                        bottom: 0.5em;
                        border-radius: 10px;
                    }
    
                    &:last-child {
                        border-bottom: 1px solid #f2f2f2;
                    }
                    
                    .card-header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0.5em;
    
                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            color: $black-color;
                            margin: 0;
                        }
    
                        .date {
                            font-size: 12px;
                            color: $text-color;
                        }
                    }
    
                    p {
                        font-size: 14px;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }
                }
            }

            .single-feedback {
                padding: 1em;

                .selected-feedback {
                    position: relative;

                    p {
                        width: 70%;
                        font-size: 18px;
                    }

                    .date {
                        font-size: 14px;
                        color: $text-color;
                    }
                }
            }
        }
        
    }
    
}