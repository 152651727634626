.manage-users {
    .action-btn {
        display: flex;
        flex-direction: row;
        column-gap: 1em;

        button { 
            display: inline;

            &.border-btn {
                background-color: transparent;
                border: 2px solid $primary-color;
                border-radius: 40px;
                padding: 0.5em 1.5em;
                border-radius: 30px;
                color: $primary-color !important;
                transition: $transition;
                min-width: 100px;
                font-size: 16px;
            }
        }
    }
}